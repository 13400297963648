<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="车牌号" v-show="false">
              <a-input v-decorator="['number', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="车牌号">
              <car-plate-keyboard @change="numbersubmit" @submit="numbersubmit" style="line-height:0!important; max-width: 300px;"></car-plate-keyboard>
            </a-form-item>
            <a-form-item label="事由">
              <a-input maxLength="200" v-decorator="['reason', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="手动增添物品">
              <vxe-toolbar>
                <template v-slot:buttons>
                  <vxe-button @click="insertEvent">新增</vxe-button>
                  <vxe-button @click="$refs.xTable.removeCheckboxRow()">删除选中</vxe-button>
                  <!--                  <vxe-button @click="importItems">通过台账导入</vxe-button>-->
                </template>
              </vxe-toolbar>
              <vxe-table
                border
                resizable
                show-overflow
                ref="xTable"
                :edit-config="{trigger: 'click', mode: 'row'}"
                :data="tableData"
                @edit-actived="editActivedEvent"
                :toolbarConfig="{ perfect: false}"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="action" title="进出区" :edit-render="{name: 'select', options: inout}"></vxe-table-column>
                <vxe-table-column field="category" title="一级分类" :edit-render="{name: 'select', options: exit_list, props: {clearable: true}, events: {change: categoryChangeEvent }}"></vxe-table-column>
                <vxe-table-column field="subcategory" title="二级分类" :formatter="formatPanmeLabel2" :edit-render="{name: 'select', options: father, props: {clearable: true},events: {change: subcategoryChangeEvent }}"></vxe-table-column>
                <vxe-table-column field="name" title="名称" :edit-render="{name: 'input', defaultValue: ''}"></vxe-table-column>
                <vxe-table-column field="count" title="数量" :edit-render="{name: 'input', defaultValue: ''}"></vxe-table-column>
                <vxe-table-column field="unit" title="单位" :edit-render="{name: 'input', defaultValue: ''}"></vxe-table-column>
                <vxe-table-column field="remark" title="备注" :edit-render="{name: 'textarea', defaultValue: ''}"></vxe-table-column>
              </vxe-table>
            </a-form-item>
            <a-form-item label="台账导入物品">
              <vxe-toolbar>
                <template v-slot:buttons>
                  <vxe-button @click="importItems">通过台账导入</vxe-button>
                  <vxe-button @click="$refs.xTable2.removeCheckboxRow()">删除选中</vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTable2"
                :edit-config="{trigger: 'click', mode: 'cell'}"
                :data="tableData2"
                class="my_table_insert"
                :edit-rules="validRules"
                :toolbarConfig="{ perfect: false}"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="action" title="进出区" :formatter="formatinout"></vxe-table-column>
                <vxe-table-column field="category" title="一级分类" :formatter="formatcat"></vxe-table-column>
                <vxe-table-column field="subcategory" title="二级分类" :formatter="formatPanmeLabel2"></vxe-table-column>
                <vxe-table-column field="name" title="名称" ></vxe-table-column>
                <vxe-table-column field="count" title="数量" :edit-render="{name: 'input'}"></vxe-table-column>
                <vxe-table-column field="unit" title="单位"></vxe-table-column>
                <vxe-table-column field="remark" title="备注" ></vxe-table-column>
              </vxe-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <a-modal
      v-model="is_show"
      v-if="is_show"
      title="台账"
      :width="1080"
      :footer="null"
    >
      <div class="table-operator">
        <a-button @click="insertfrombook">插入</a-button>
      </div>
      <a-table
        :row-selection="{ onChange: onSelectChange }"
        :columns="columns"
        :data-source="dataSource"
      />
    </a-modal>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { speedy_item_list } from '@/api/speedy_item'
import storage from 'store'
import { IsPower } from '@/store/mutation-types'
// 表单字段
const fields = ['id']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    const countValid = ({ cellValue, row }) => {
      const curobj = this.selectedRows.find((item) => {
        return item.id === row.id
      })
      if (curobj && (cellValue > curobj.count)) {
        this.$message.error('数量大于当前库存')
        return new Error('数量大于当前库存')
      }
    }
    const obj = JSON.parse(storage.get(IsPower))
    /* const list = obj.allow_infrastructure ? this.$Dictionaries.GateWayItem2 : this.$Dictionaries.GateWayItem2.filter((item) => {
      return item.value !== 3
    }) */
    const list = this.$Dictionaries.GateWayItem2.filter((item) => {
      item.Show = true
      if ((!obj.allow_infrastructure && item.value === 3)) {
        item.Show = false
      }
      if ((!obj.allow_muck && item.value === 5)) {
        item.Show = false
      }
      return item.Show
    })
    return {
      validRules: {
        count: [
          { required: true, message: '必填项' },
          { validator: countValid }
        ]
      },
      dataSource: [],
      is_show: false,
      tableData: [],
      tableData2: [],
      entrance: {},
      exit: {},
      list: [],
      cachePnameList: [],
      inout: [{ label: '进区', value: 3 }, { label: '出区', value: 4 }],
      selectedRows: [],
      exit_list: list,
      form: this.$form.createForm(this),
      father: this.$Dictionaries.GateWayItem2[0].children,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13, offset: 7 }
        }
      },
      // 表头
      columns: [
        {
          title: '一级分类',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.Category[text],
          ellipsis: true
        },
        {
          title: '二级分类',
          dataIndex: 'subcategory',
          customRender: (text) => this.$Dictionaries.Subcategory[text],
          ellipsis: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '单位',
          dataIndex: 'unit',
          ellipsis: true
        },
        {
          title: '区内数量总计',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '是否补录',
          dataIndex: 'is_additional',
          customRender: (text) => text ? '是' : '否'
        }/*,
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          } */
      ]
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    numbersubmit (value) {
      console.log(value)
      this.form.setFieldsValue({
        number: value
      })
    },
    importItems () {
      this.is_show = true
      speedy_item_list({ corporation_id: this.$Dictionaries.corporation_id, no_zero: true }).then(({ data }) => {
        console.log(data.entries)
        data.entries.forEach((item) => {
          item.speedy_item_id = item.id
        })
        this.dataSource = data.entries
      })
    },
    insertfrombook () {
      const xTable = this.$refs.xTable2
      this.selectedRows.forEach((item) => {
        item.action = 4
      })
      console.log(xTable.afterFullData, '列表数据')
      if (xTable.afterFullData.length > 0) {
        this.current_row_id = xTable.afterFullData.map((item) => {
          return item.id
        })
        this.selectedRows = this.selectedRows.filter((item) => {
          return this.current_row_id.indexOf(item.id) === -1
        })
      }
      const newRow = this.selectedRows
      xTable.insert(newRow).then(() => {
        this.is_show = false
        console.log(xTable)
      })
    },
    editActivedEvent ({ row }) {
      this.updatePnameList(row)
    },
    // 格式化显示名称
     /* formatPanmeLabel ({ cellValue, row }) {
      let ptype = row.category
      // eslint-disable-next-line eqeqeq
      let ptypeItem = this.$Dictionaries.GateWayItem2.find(item => item.value == ptype)
      if (ptypeItem && ptypeItem.children) {
        // eslint-disable-next-line eqeqeq
        let pnameItem = ptypeItem.children.find(item => item.value == cellValue)
        if (pnameItem) {
          return pnameItem.label
        }
      } else {
        return ''
      }
    }, */
    formatPanmeLabel2 ({ cellValue, row }) {
      console.log(cellValue)
      return this.$Dictionaries.Subcategory[cellValue]
    },
    formatcat ({ cellValue, row }) {
      return this.$Dictionaries.Category[cellValue]
    },
    formatinout ({ cellValue, row }) {
      return this.$Dictionaries.inout[cellValue]
    },
    // 更新级联选项列表
    updatePnameList (row) {
      console.log('我要更新', row)
      let ptype = row.category
      let pnameList = []
      if (ptype) {
        // eslint-disable-next-line eqeqeq
        let item = this.$Dictionaries.GateWayItem2.find(item => item.value == ptype)
        if (item) {
          pnameList = item.children
        } else {
          pnameList = []
        }
      }
      this.father = pnameList
    },
    categoryChangeEvent ({ row }) {
      // 类型切换时更新级联的下拉数据
      row.subcategory = ''
      this.updatePnameList(row)
    },
    subcategoryChangeEvent ({ row }) {
      console.log(row)
    },
    insertEvent () {
      const xTable = this.$refs.xTable
      let newRow = { category: 1, action: 3 }
      xTable.insert(newRow)
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys changed: ', selectedRows)
      this.selectedRows = selectedRows
    },
    /* ------------------------- */
    ok () {
      const x = this.$refs.xTable.afterFullData
      const x2 = this.$refs.xTable2.afterFullData
      this.$emit('ok', x.concat(x2))
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
