import request from '@/utils/request'

const speedy_applicationApi = {
  speedy_application_list: '/speedy_application/',
  speedy_application_create: '/speedy_application/',
  speedy_application_img_list: '/speedy_application/img/',
  speedy_application_record_list: '/speedy_application/record/',
  speedy_application_notice_list: '/speedy_application/notice/',
  speedy_application_notice_create: '/speedy_application/notice/',
  speedy_application_partial_update: '/speedy_application/',
  speedy_application_delete: '/speedy_application/'
}

/**
 * 通知
 */
export function speedy_application_notice_create (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_notice_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function speedy_application_notice_list (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_notice_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function speedy_application_list (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function speedy_application_img_list (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_img_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function speedy_application_record_list (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function speedy_application_create (parameter) {
  return request({
    url: speedy_applicationApi.speedy_application_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function speedy_application_partial_update (parameter, speedy_application_id) {
  return request({
    url: speedy_applicationApi.speedy_application_partial_update + speedy_application_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function speedy_application_delete (speedy_application_id) {
  return request({
    url: speedy_applicationApi.speedy_application_delete + speedy_application_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
