<template>
  <a-modal
    title="台账导入"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="车牌">
              <a-input v-decorator="['number', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="事由">
              <a-input maxLength="200" v-decorator="['reason', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="物品">
              <vxe-toolbar>
                <template v-slot:buttons>
                  <!--                  <vxe-button @click="insertEvent">新增</vxe-button>-->
                  <vxe-button @click="$refs.xTable.removeCheckboxRow()">删除选中</vxe-button>
                  <vxe-button @click="importItems">通过台账导入</vxe-button>
                </template>
              </vxe-toolbar>
              <vxe-table
                ref="xTable"
                :edit-config="{trigger: 'click', mode: 'cell'}"
                :data="tableData"
                class="my_table_insert"
                @edit-actived="editActivedEvent"
                :edit-rules="validRules"
                :toolbarConfig="{ perfect: false}"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="action" title="进出区" :formatter="formatinout"></vxe-table-column>
                <vxe-table-column field="category" title="一级分类" :formatter="formatcat"></vxe-table-column>
                <vxe-table-column field="subcategory" title="二级分类" :formatter="formatPanmeLabel"></vxe-table-column>
                <vxe-table-column field="name" title="名称" ></vxe-table-column>
                <vxe-table-column field="count" title="数量" :edit-render="{name: 'input'}"></vxe-table-column>
                <vxe-table-column field="unit" title="单位"></vxe-table-column>
                <vxe-table-column field="remark" title="备注" ></vxe-table-column>
              </vxe-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
    <a-modal
      v-model="is_show"
      v-if="is_show"
      title="台账"
      :width="1080"
      :footer="null"
    >
      <div class="table-operator">
        <a-button @click="insertfrombook">插入</a-button>
      </div>
      <a-table
        :row-selection="{ onChange: onSelectChange }"
        :columns="columns"
        :data-source="dataSource"
      />
    </a-modal>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { speedy_item_list } from '@/api/speedy_item'
// 表单字段
const fields = ['id']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    }
    const countValid = ({ cellValue, row }) => {
      const curobj = this.selectedRows.find((item) => {
        return item.id === row.id
      })
      if (cellValue > curobj.count) {
        this.$message.error('数量大于当前库存')
        return new Error('数量大于当前库存')
      }
    }
    return {
      validRules: {
        count: [
          { required: true, message: '必填项' },
          { validator: countValid }
          ]
      },
      current_row_id: [],
      dataSource: [],
      is_show: false,
      tableData: [],
      entrance: {},
      exit: {},
      list: [],
      cachePnameList: [],
      inout: [{ label: '进区', value: 3 }, { label: '出区', value: 4 }],
      selectedRows: [],
      exit_list: this.$Dictionaries.GateWayItem2,
      form: this.$form.createForm(this),
      father: this.$Dictionaries.GateWayItem2[0].children,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13, offset: 7 }
        }
      },
      // 表头
      columns: [
        {
          title: '一级分类',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.Category[text],
          ellipsis: true
        },
        {
          title: '二级分类',
          dataIndex: 'subcategory',
          customRender: (text) => this.$Dictionaries.Subcategory[text],
          ellipsis: true
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '单位',
          dataIndex: 'unit',
          ellipsis: true
        },
        {
          title: '区内数量总计',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '是否补录',
          dataIndex: 'is_additional',
          customRender: (text) => text ? '是' : '否'
        }/*,
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          } */
      ]
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    importItems () {
      this.is_show = true
      speedy_item_list({ corporation_id: this.$Dictionaries.corporation_id }).then(({ data }) => {
        console.log(data.entries)
        this.dataSource = data.entries
      })
    },
    insertfrombook () {
      const xTable = this.$refs.xTable
      this.selectedRows.forEach((item) => {
        item.action = 4
      })
      console.log(xTable.afterFullData, '列表数据')
      if (xTable.afterFullData.length > 0) {
        this.current_row_id = xTable.afterFullData.map((item) => {
          return item.id
        })
        this.selectedRows = this.selectedRows.filter((item) => {
          return this.current_row_id.indexOf(item.id) === -1
        })
      }
      const newRow = this.selectedRows
      xTable.insert(newRow).then(() => {
          this.is_show = false
          console.log(xTable)
        })
    },
    editActivedEvent ({ row }) {
      this.updatePnameList(row)
    },
    // 格式化显示名称
    formatPanmeLabel ({ cellValue, row }) {
     return this.$Dictionaries.Subcategory[cellValue]
    },
    formatcat ({ cellValue, row }) {
      return this.$Dictionaries.Category[cellValue]
    },
    formatinout ({ cellValue, row }) {
      return this.$Dictionaries.inout[cellValue]
    },
    // 更新级联选项列表
    updatePnameList (row) {
      let ptype = row.category
      let pnameList = []
      if (ptype) {
        // eslint-disable-next-line eqeqeq
        let item = this.$Dictionaries.GateWayItem2.find(item => item.value == ptype)
        if (item) {
          pnameList = item.children
        } else {
          pnameList = []
        }
      }
      this.father = pnameList
      row.subcategory = pnameList[0].value
    },
    categoryChangeEvent ({ row }) {
      // 类型切换时更新级联的下拉数据
      row.subcategory = ''
      this.updatePnameList(row)
    },
    insertEvent () {
      const xTable = this.$refs.xTable
      let newRow = { category: 1, subcategory: 1, action: 3 }
      xTable.insert(newRow)
    },

    onSelectChange (selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys changed: ', selectedRows)
      this.selectedRows = selectedRows
    },
    /* ------------------------- */
    ok () {
      this.$emit('ok', this.$refs.xTable.afterFullData)
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
