<template>
  <div>
    <a-result title="申请后开启" sub-title="申请后开启" v-if="!is_show">
      <template #extra>
        <p style="font-size: 16px;">
          <a href="https://corporation.jnzbtz.com/inout/undertaking">  <strong>以上资料需打印加盖公章后上传至系统，点击上传</strong> </a>
        </p>
        <div>
          <a-button type="primary" @click="showModal" v-if="timeDetail">
            通知管理员审核
          </a-button>
          <a-button type="primary" v-if="!timeDetail" disabled>
            <span class="span">{{ hh }}:{{ mm }}:{{ ss }}后可再次通知管理员审核</span>
          </a-button>
          <a-modal v-model="applyvisible" title="申请确认" @ok="handleApplyOk" ok-text="确认" cancel-text="取消">
            <p>1.请确认您已上传以上所有要求的文件</p>
            <p>2.该申请24小时之内只能申请一次</p>
          </a-modal>
        </div>
      </template>
      <p style="font-size: 16px;" class="tiptitle">
        <strong>申请开通请提供如下内容(点击名称可下载文件)：</strong>
      </p>
      <div class="desc">
        <p style="font-size: 16px;">
          <strong>一.海关注册企业（有海关编码）：</strong>
        </p>
        <p>
          1.<a href="https://static.jnzbtz.com/%E9%80%82%E7%94%A8%E4%BE%BF%E6%8D%B7%E8%BF%9B%E5%87%BA%E5%8C%BA%E7%AE%A1%E7%90%86%E6%A8%A1%E5%BC%8F%E5%A3%B0%E6%98%8E.docx">适用便捷进出区管理模式声明</a>
        </p>
        <p>
          2.<a href="https://static.jnzbtz.com/%E5%AE%89%E5%85%A8%E8%B4%A3%E4%BB%BB%E6%89%BF%E8%AF%BA%E4%B9%A6.doc">安全责任承诺书（如无装修建设项目不需要提供）</a>
        </p>
        <p>
          3.营业执照扫描件
        </p>
        <p style="font-size: 16px;">
          <strong>二.基建企业：</strong>
        </p>
        <p>
          1.<a href="https://static.jnzbtz.com/%E5%AE%89%E5%85%A8%E8%B4%A3%E4%BB%BB%E6%89%BF%E8%AF%BA%E4%B9%A6.doc">安全责任承诺书</a>
        </p>
        <p>
          2.<a href="https://static.jnzbtz.com/%E5%AE%89%E5%85%A8%E6%96%BD%E5%B7%A5%E6%89%BF%E8%AF%BA%E4%B9%A6.docx">安全施工承诺书与支付凭证截图</a>
        </p>
        <p>
          3.<a href="https://static.jnzbtz.com/%E6%B8%A3%E5%9C%9F%E8%BD%A6%E8%BF%9B%E5%87%BA%E5%9B%AD%E5%8C%BA%E7%94%B3%E6%8A%A5%E5%8D%95.docx">渣土车进出园区申报单（渣土进出需要）</a>
        </p>
        <p>
          4.施工合同
        </p>
        <p>
          5.营业执照扫描件
        </p>
      </div>
    </a-result>
    <div v-if="is_show">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="时间">
                <a-range-picker @change="time" format="YYYY-MM-DD HH:mm:ss">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select mode="tags" placeholder="请选择" @change="handleStatusChange">
                  <a-select-option v-for=" (value, key) in $Dictionaries.GateWayStatus" :value="key" :key="value"> {{ value }} </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons" >
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleLook(record)">详情</a>
            <span v-if="record.status<3" v-action:delete>
              <a-divider
                type="vertical"
              />
              <a-popconfirm
                title="确定要删除么？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelet(record)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </span>
      </s-table>
      <create-form
        v-if="visible"
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
      <disable-form
        v-if="disvisible"
        ref="createdisModal"
        :visible="disvisible"
        :loading="disconfirmLoading"
        :model="dismdl"
        @cancel="handleAdddisCancel"
        @ok="handledisOk"
      />
      <a-modal
        title="选择创建方式"
        :visible="selectvisible"
        :confirm-loading="selectconfirmLoading"
        @ok="handleselectOk"
        @cancel="handleselectCancel"
      >
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-button type="primary" @click="opendis">台账导入</a-button>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-button @click="diyadd">手动添加</a-button>
          </a-col>
        </a-row>
      </a-modal>
    </div>

  </div>
</template>

<script>
import { STable } from '@/components'
import {
  speedy_application_list,
  speedy_application_create,
  speedy_application_delete,
  speedy_application_notice_create, speedy_application_notice_list
} from '@/api/speedy_application'
import { Dictionaries } from '@/common/AllConstant'
/*
import CreateForm from '../modules/CreateForm'
*/
import CreateForm from '../modules/CreateInoutForm'
import DisableForm from '../modules/CreateInouDisableForm'

import storage from 'store'
import { CORPORATION, IsPower } from '@/store/mutation-types'
/*
import pick from 'lodash.pick'
*/
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    DisableForm
  },
  data () {
    return {
      hh: '00',
      mm: '00',
      ss: '00',
      flag: false,
      timecir: null,
      timeDetail: true,
      applyvisible: false,
      confirmLoading: false,
      mdl: {},
      visible: false,
      disvisible: false,
      dismdl: {},
      disconfirmLoading: false,
      selectconfirmLoading: false,
      selectvisible: false,
      dictionaries: Dictionaries,
      DrawerVisible: false,
      current_speedy_application_id: null,
      // 创建窗口控制
      tags: [],
      selectedTags: [],
      is_show: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      loading: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '车牌号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '事由',
          dataIndex: 'reason',
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          customRender: (text) => this.$Dictionaries.GateWayStatus[text]
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          align: 'center',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return speedy_application_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    console.log(JSON.parse(storage.get(IsPower)))
    const obj = JSON.parse(storage.get(IsPower))
    this.is_show = obj.allow_gateway_application
    this.getTime()
  },
  methods: {
    timeDown (beginTime) {
      const endTime = new Date(beginTime).getTime() + 24 * 60 * 60 * 1000
      const nowTime = new Date(beginTime)
      var date = new Date()
      var now = date.getTime(nowTime)
      // 设置截止时间
      var endDate = new Date(endTime)
      var end = endDate.getTime()
      // 时间差
      var rightTime = end - now// 截止时间减去当前时间
      if (rightTime > 0) { // 判断剩余倒计时时间如果大于0就执行倒计时否则就结束
        // var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
        var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24)
        var mm = Math.floor((rightTime / 1000 / 60) % 60)
        var ss = Math.floor((rightTime / 1000) % 60)
        this.hh = hh
        this.mm = mm
        this.ss = ss
      } else {
        this.flag = true
        window.location.reload()
      }
    },
    getTime () {
      speedy_application_notice_list().then(res => {
        console.log(res, '时间')
        if (!res.success) {
          this.timeDetail = false
          this.timecir = setInterval(() => {
            if (this.flag === true) {
              clearInterval(this.timecir)
            }
            this.timeDown(res.time)
          }, 1000)
        }
      })
    },
    showModal () {
      this.applyvisible = true
    },
    handleApplyOk (e) {
      speedy_application_notice_create({}).then((res) => {
        console.log(res)
        if (!res.success) {
          this.timeDetail = false
          this.timecir = setInterval(() => {
            if (this.flag === true) {
              clearInterval(this.timecir)
            }
            this.timeDown(res.time)
          }, 1000)
        }
      })
      this.applyvisible = false
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleStatusChange (value) {
      console.log(`selected ${value}`)
      this.queryParam.statuses = value.join(',')
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      this.confirmLoading = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAdddisCancel () {
      this.disvisible = false
      this.disconfirmLoading = false
      const form = this.$refs.createdisModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      speedy_application_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleLook (record) {
      // this.$emit('onLook', record)
      this.$router.push({ path: '/car/Quick/' + record.id })
    },
    afterVisibleChange (val) {
      console.log('visible', val)
    },
    handleOk (data) {
      console.log(data)
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, this.tableData)
          const cor = JSON.parse(storage.get(CORPORATION))
          values.corporation_id = cor.id
          values.items = data
          // 新增
          speedy_application_create(values).then(res => {
            if (res.success) {
              this.$message.success('成功')
            }
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((error) => {
              console.log(error, 'err')
              this.confirmLoading = false
            })
            .finally(() => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handledisOk (data) {
      console.log(data)
      const form = this.$refs.createdisModal.form
      this.disconfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, this.tableData)
          const cor = JSON.parse(storage.get(CORPORATION))
          values.corporation_id = cor.id
          values.items = data
          // 新增
          speedy_application_create(values).then(res => {
            this.disvisible = false
            this.disconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((error) => {
              console.log(error, 'err')
              this.disconfirmLoading = false
            })
            .finally(() => {
              this.disconfirmLoading = false
            })
        } else {
          this.disconfirmLoading = false
        }
      })
    },
    handleselectOk () {
      this.selectvisible = true
    },
    opendis () {
      this.disvisible = true
      this.selectvisible = false
    },
    diyadd () {
      this.visible = true
      this.selectvisible = false
    },
    handleselectCancel () {
      this.selectvisible = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {}
    }
  }
}
</script>
<style>
.desc {
  padding-left: 50px;
}

</style>
