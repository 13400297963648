<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-button @click="handleGoBack" type="info">返回</a-button>
      <a-table :columns="columns" :data-source="recordList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px">
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
    </a-card>
    <a-card
      :title="item.action===3?'进区':'出区'"
      style="margin-bottom: 24px"
      :bordered="false"
      :body-style="{padding: 30}"
      v-for="(item, index) in info"
      :key="index"
    >
      <div class="item-group">
        <a-descriptions>
          <a-descriptions-item label="分类">
            {{ Category[item.category] }} - {{ Subcategory[item.subcategory] }}
          </a-descriptions-item>
          <a-descriptions-item label="名称">
            {{ item.name }}
          </a-descriptions-item>
          <a-descriptions-item label="数量">
            {{ item.count }}/{{ item.unit }}
          </a-descriptions-item>
          <a-descriptions-item label="备注">
            {{ item.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-card>
    <a-card hoverable style="width: 240px; display: inline-block;margin-right: 25px; margin-top: 25px" v-for="(item, index) in img" :key="index+1000">
      <img
        slot="cover"
        alt="example"
        :src="item&&item.img"
        @click="openPreview(item.img)"
      />
      <a-card-meta :title="item.action===3?'进区':'出区'">
      </a-card-meta>
    </a-card>
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
// import moment from 'moment'
import { speedy_application_img_list, speedy_application_record_list } from '@/api/speedy_application'
import { speedy_item_detail_list } from '@/api/speedy_item'
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      previewvisible: false,
      previewurl: '',
      info: {},
      img: [],
      user: {},
      recordList: [],
      Category: this.$Dictionaries.Category,
      Subcategory: this.$Dictionaries.Subcategory,
      columns: [
        {
          title: '创建时间',
          dataIndex: 'create_time',
          customRender: (text) => text || '-',
          ellipsis: true
        },
        {
          title: '主负责人',
          dataIndex: 'major_allow_by',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '次负责人',
          dataIndex: 'minor_allow_by',
          align: 'center',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'action',
          align: 'center',
          customRender: (text) => text === 3 ? '进区' : '出区',
          ellipsis: true
        }
      ],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    const record = { id: this.$route.params.id }
    this.$nextTick(() => {
      console.log(record)
      this.getInfo(record)
      this.getImg(record)
      this.getRecord(record)
    })
  },
  methods: {
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    getRecord (record) {
      speedy_application_record_list({ speedy_application_id: record.id }).then(({ data }) => {
        console.log(data, '1')
        this.recordList = data
      })
    },
    getInfo (record) {
      speedy_item_detail_list({ speedy_application_id: record.id }).then(({ data }) => {
        console.log(data, '2')
        this.info = data.entries
      })
    },
    getImg (record) {
      speedy_application_img_list({ speedy_application_id: record.id }).then(({ data }) => {
        console.log(data, '3')
        this.img = data
      })
    }
  }
}
</script>
